export const OUTPUT_FORMAT_EXPORT = ['xls', 'pdf', 'txt', 'csv', 'csv', 'custom'];

export const USERS_MENU_LIST_EXPENDED = [71, 18, 1110, 11100]; // msgId for "Production = 18", "Finance = 1110" and "Sustainability = 11100"

export const FILENAME_REPORT = {
	EXPORT_EXPENSE: 'Expense Export',
	EXPORT_PO: 'Purchase Order Export',
	EXPORT_TS: 'Timesheet Export',
	EXPORT_USER: 'User Export',
	REPORT_EXPENSE: 'Expense Report',
	REPORT_PO: 'Purchase Order Report',
	REPORT_TS: 'Timesheet Report',
	REPORT_USER: 'Infosheet Report',
	REPORT_EXPENSE_ITEM: 'Expense Item Report',
	REPORT_JOB_REQUEST: 'Job Request Report',
	REPORT_BUDGET_PER_CATEGORY: 'Budget Per Category'
};

// User status
export const USER_STATUS_MENU = 12006;
export const USER_STATUS_DRAFT = 0;
export const USER_STATUS_ACTIVE = 1;
export const USER_STATUS_DISABLE = 2;

// DPAE status menu
export const DPAE_STATUS_MENU = 2016;

export const DPAE_STATUS = {
	NOT_SENT: 0,
	NOT_APPLICABLE_STATUS: 1,
	PROCESSING: 2,
	CONFIRMED: 3,
	FAILED: 4,
	NOT_SENT_MISSING_INFO: 5
};

export const OODRIVE_RETURN_PAGE_TYPE = {
	SUCCESS: 'success',
	FAILURE: 'failure'
};

export const ACTION = {
	ADD: 'ADD',
	UPDATE: 'UPDATE'
};

export const FLAG_TYPE = {
	TAX: 0,
	DEFAULT_FLAG: 1
};

export const INPUT_TYPE = {
	TEXT: 0,
	INTEGER: 1,
	FLOAT: 2
};

export const SUPPLIER = {
	STANDARD: 0,
	ACCOMODATION: 1,
	ELECTRICITY: 2,
	LOCATION: 3,
	OWNER: 4,
	LICENSE_OLDER: 5,
	CLIENT: 6,
	TENANT: 7,
	GREEN: 8,
	LEGAL_ENTITY: 9,
	ARCHITECT: 10,
	SYNDIC: 11,
	OFFER: 12,
	GAS: 13,
	WATER: 14
};

export const EXPENSE_TYPE = {
	TICKET: 0,
	INVOICE: 1,
	TRAVEL: 2
};

export const EXPENSE_CARBON_ENTITY_TYPE = 1;

export const EXPENSE_NAME = ['Ticket', 'Invoice', 'Travel'];

export const ERROR_STRING = {
	ITEM_NOT_FOUND: 'Item not found'
};

export const TRANSPORTATION_MEAN_MENU = {
	BOAT: 1404,
	BUS: 1405,
	CAR: 1406,
	FREIGHT: 1418,
	OTHER: 1407,
	SPECIAL_VEHICLE: 1461
};

export const TRANSPORTATION_MEAN_DETAIL_MENU = {
	CAR: 1408,
	PLANE: 1419,
	SCOOTER: 1420,
	TRAIN: 1421,
	VAN: 1422,
	PLANE_TYPE: 1423,
	HIRE_RENTAL: 1424
};

export const MEAL_TYPE_MENU = 1426;

export const ENERGY_TYPE_MENU = 1425;

export const WASTE_STANDARD_TYPE_MENU = 1427;

export const WASTE_DESTRUCTION_TYPE_MENU = 1428;

export const REASON_ENERGY_TYPE_MENU = 1429;

export const ENERGY_TYPE_FOR_LOCATION_PROMISE = 1430;

export const CAR_LODGE_ENERGY_TYPE_MENU = 1431;

export const CARBON_VERIFIED_STATUS_MENU = 1433;

export const CARBON_PRODUCTION_LOGISTIC_TYPE = 1435;

export const CARBON_CLAP_COUNTRIES_MENU = 11035;

export const INIT_DATA_CARBON = {
	//A VOIR
	strDate: new Date(),
	endDate: new Date(),
	description: '',
	amount: 0,
	entityType: 8,
	coTwoCategory: 0,
	entityId: 0,
	type: 0,
	subType: 0,
	additionalSubType: 0,
	paidByProduction: false,
	verified: 0,
	km: 0,
	liter: 0,
	mcube: 0,
	kwh: 0,
	msquare: 0,
	number: 0,
	kg: 0,
	waterVolumeUsed: 0,
	supplierId: 0,
	boolValue: false,
	mealRed: 0,
	mealWhite: 0,
	mealVege: 0,
	mealNotSpecified: 0,
	numberOfNight: 0,
	numberBedroom: 0,
	elecSupplier: 0,
	elecMeterStart: 0,
	elecMeterEnd: 0,
	gasSupplier: 0,
	gasMeterStart: 0,
	gasMeterEnd: 0,
	waterSupplier: 0,
	waterMeterStart: 0,
	waterMeterEnd: 0,
	vehiculeId: 0,
	numberOfVehicule: 0,
	fromAddressId: 0,
	toAddressId: 0,
	kgCardboardWaste: 0,
	kgPaperWaste: 0,
	kgPlasticWaste: 0,
	kgFoodWaste: 0,
	kgGeneralWaste: 0,
	kgPaintWaste: 0,
	kgWoodWaste: 0,
	kgSteelWaste: 0,
	unitBatterieWaste: 0,
	amountVat: 0,
	amountTotal: 0,
	locationId: 0,
	carbonId: 0,
	sortedWaste: true,
	recycledPaper: false,
	greenEnergySupplier: false,
	consumptionElectricity: 0,
	consumptionGas: 0,
	consumptionWater: 0,
	locationSetName: '',
	typeMeasurement: 1,
	navigationDuration: 0,
	fuelConsumption: 0,
	productionPhase: 0,
	postProductionMeansType: 0,
	numberOfWorkingDays: 0,
	numberOfExtraStaffDays: 0,
	specialEquipmentId: 0,
	reportType: 0
};

/**
 * Item as exepnse, po, carbon
 */
export const FLAG_ENTITY_ITEM_TYPE = {
	expense: 0,
	po: 1,
	carbon: 2
};

export const PROJECT_CARBON_FIELD = `
  id
  createdAt
  updatedAt
  reportType
  projectId
  entityId
  templateId
  templateFound
  description
  amount
  entityType
  strDate
  endDate
  coTwoCategory
  type
  subType
  additionalSubType
  paidByProduction
  verified
  km
  totalKm
  liter
  mcube
  kwh
  msquare
  number
  waterVolumeUsed
  supplierId
  userId
  kgCoTwo
  boolValue
  mealRed
  mealWhite
  mealVege
  mealNotSpecified
  numberOfNight
  numberBedroom
  elecSupplier
  elecMeterStart
  elecMeterEnd
  gasSupplier
  gasMeterStart
  gasMeterEnd
  waterSupplier
  waterMeterStart
  waterMeterEnd
  reason
  vehiculeId
  numberOfVehicule
  fromAddressId
  toAddressId
  toAddress
  fromAddress
  kgCardboardWaste
  kgPaperWaste
  kgPlasticWaste
  kgFoodWaste
  kgGeneralWaste
  kgPaintWaste
  kgWoodWaste
  kgSteelWaste
  kgGlassWaste
  userProjectId
  updated
  rawText
  locationId
  jsonData
  costCenter
  fuelOilVolumeUsed
  ancillarySpaceConsumption
  sortedWaste
  recycledPaper
  greenEnergySupplier
  consumptionElectricity
  consumptionGas
  consumptionWater
  haveDocument
  locationSetName
  typeMeasurement
  navigationDuration
  fuelConsumption
  productionPhase
  postProductionMeansType
  numberOfWorkingDays
  numberOfExtraStaffDays
  supplier {
    id
    name
  }
  user {
    id
    name
    firstName
  }
  department {
    id
    value
    message
  }
  function {
    id
    value
    message
  }
  carbonValues {
    id
    createdAt
    updatedAt
    carbonId
    carbonModel
    kgCoTwo
  }
  carbonTemplate {
    id
    name
    position
  }
  specialEquipmentId
  specialEquipment {
	name
	energyType
  }
`;

export const OODRIVE_VALID_DOCUMENT_EXT = ['pdf', 'doc', 'docx'];

export const EXT_TO_ACCEPT = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'md', 'txt'];

export const DOCUMENT_VALIDATION_STEP = {
	step1: 1,
	step2: 2,
	step3: 3
};

export const DOCUMENT_VALIDATION_LEVEL = {
	gateKeeper: 5,
	productionAccountant: 6,
	upm: 7,
	lineProducer: 8,
	studio: 9
};

export const VALIDATION_TYPE = {
	NOT_SUBMITTED: 0,
	SUBMITTED: 1,
	VALIDATED_LEVEL_1: 2,
	REFUSED_LEVEL_1: 4,
	VALIDATED_LEVEL_2: 8,
	REFUSED_LEVEL_2: 16
};

/**
 * MP: Northern Mariana Islands
 * GU: Guam
 * AS: American Samoa
 * PR: Pureto Rico
 * CA: Canada
 * BS: Bahamas
 * BB: Barbados
 * AI: Anguilla
 * AG: Antigua and Barbuda
 * VG: British Virgin Islands
 * KY: Cayman Islands
 * BM: Bermuda
 * GD: Grenada
 * TC: Turks and Caicos Islands
 * JM: Jamaica
 * MS: Montserrat
 * SX: Sint Maarten
 * LC: Saint Lucia
 * DM: Dominica
 * VC: Saint Vincent and the Grenadines
 * DO: Dominican Republic
 * TT: Trinidad and Tobago
 * KN: Saint Kitts and Nevis
 * US: United states
 * @type {string[]}
 */
export const NANP_COUNTRY_CODES = [
	/*
	'MP',
	'GU',
	'AS',
	'PR',
	'CA',
	'BS',
	'BB',
	'AI',
	'AG',
	'VG',
	'KY',
	'BM',
	'GD',
	'TC',
	'JM',
	'MS',
	'SX',
	'LC',
	'DM',
	'VC',
	'DO',
	'TT',
	'KN',
	'US'
  */
];

/**
 * OCR_SCAN_ERROR: When importing the file, an error occurs
 * OCR_IMPORT_ERROR: Duplicate the file import
 * @type {{OCR_IMPORT_ERROR: number, OCR_SCAN_ERROR: number}}
 */
export const IMPORT_CARBON_OCR_STATUS = {
	OCR_SCAN_ERROR: 400,
	OCR_IMPORT_ERROR: 208
};

export const EXPENSE_TREE_NAME = {
	PARENT: 'Expense',
	CHILDREN: 'ExpenseItem'
};

export const ACCOMMODATION_STATUS = {
	NOT_PUBLISHED: 0,
	NOT_RECEIVED: 1,
	RECEIVED_NOT_READ: 2,
	READ: 3,
	SIGNED: 4
};

export const STUDY_LEVEL_MENU = 1441;

export const SALARY_TYPE_MENU = 1442;

export const JOB_REQUEST_STATUS_MENU = 1443;

export const TRANSPORT_TYPE_MEASUREMENT_MENU = 1444;

export const PRODUCTION_PHASE_MENU = 1445;

export const GEOGRAPHIC_AREA_MENU = 1446;

export const PREMISE_TYPE_MENU = {
	OFFICE: 11036,
	DESIGN: 11037,
	HMC: 11038
};

export const ACTIVITY_TYPE_MENU = 1449;

export const REPORTING_TYPE_MENU = 1450;

export const POST_PRODUCTION_MEANS_MENU = 1451;

export const DEVICE = {
	WEB: 'web',
	IOS: 'ios',
	ANDROID: 'android'
};

/**
 * SPE: SPECIAL EQUIPMENT
 */
export const SPE_GENERATOR_TYPE_MENU = 1454;
export const SPE_GENERATOR_ENERGY_TYPE_MENU = 1455;
export const SPE_EQUIPMENT_TYPE_MENU = 1456;
export const SPE_TRAILER_TYPE_MENU = 1460;
export const SPE_GENERATOR_TYPE = 1;
export const SPE_PRODUCTION_ROOMS_TYPE = 2;
export const SPE_TRAILER_TYPE = 3;

// SPE_VEHICLE: SPECIAL VEHICLE TYPE
export const SPE_VEHICLE_GENERATOR_TRUCK_TYPE = 26;
export const SPE_VEHICLE_PRODUCTION_TRUCK_TYPE = 27;
export const SPE_VEHICLE_TRAILER_TYPE = 28;

export const WASTE_TYPE_MENU = 11050;

export const CARBON_CLAP_STATUS_MENU = 11051;
export const CARBON_CLAP_STATUS_IN_PROGRESS = 1;
export const CARBON_CLAP_STATUS_FAILED = 2;
export const CARBON_CLAP_STATUS_OK = 3;

export const COLOR_ACTION = {
	ERROR: {
		light: '#FCEDE9',
		dark: '#EA4E2C'
	},
	WARNING: {
		light: '#FBF5E5',
		dark: '#CD9A16'
	},
	SUCCESS: {
		light: '#ECF6E8',
		dark: '#3C7442'
	},
	INFO: {
		light: '#E7F2FF',
		dark: '#225CBD'
	}
};

export const EXPENSE_GQL = `
	expense {
		id
		date
		description
		amountVat
		amount
		amountTotal
		type
		kgCoTwo
		kmRate
		validated
		validationStatus
		user {
			name
			firstName
			fullName
			departmentName
			functionName
		}
	}
`;

export const CUSTOM_FIELD_TYPE = {
	Checkbox: 0,
	Date: 1,
	Dropdown: 2,
	Freetext: 3,
	Number: 4,
	Percentage: 5,
	Radiobutton: 6
};

export const CUSTOM_FIELD_PARENT_TYPE = {
	user: 0,
	userProject: 1,
	contract: 2
};

export const ROUTE_NAME = {
	jobRequestDetail: 'jobRequestDetails',
	configProjectAdmin: 'project-administration',
	profile: 'profile',
	expenseDetail: 'Expense Details',
	budgetExpenseDetail: 'budget-expense-sheet-details'
};

export const REG_SEARCH_FORMAT = {
	pattern: /[\s"'\/\\]+/g
};

export const LANG_RES_TYPE = {
	msg: 0, // application messages
	menu: 1, // menu and combo choices
	comment: 2, // comments in the language file (not used in the application)
	form: 3, // screen messages for user interface
	formWeb: 4, // screen messages for user interface of web application
	keyWord: 5 // menu AI
};

export const SCRIPT_TYPE = {
	EXPENSE: 0,
	EXPENSE_INVOICE: 2,
	TIMESHEET_INVOICE: 3,
	EXPENSE_SHEET_EXPORT: 4,
	TASK_INVOICE: 5,
	TASK_OFFER: 6,
	USER: 7,
	PURCHASE_ORDER: 8,
	PURCHASE_ORDER_EXPORT: 9,
	TIMESHEET: 10,
	TIMESHEET_EXPORT: 11,
	USER_EXPORT: 12,
	IMPORT_CUSTOM_FIELD_TEMPLATE: 13,
	EXPENSE_ITEMS_EXPORT: 14,
	DIGITAL_CERTIFIED_EXPENSE_TYPE_TICKET: 15,
	DIGITAL_CERTIFIED_EXPENSE_TYPE_MILEAGE: 16,
	DIGITAL_CERTIFIED_EXPENSE_TYPE_INVOICE: 17,
	PO_FOR_JOB_REQUEST: 18,
	PO_FOR_JOB_REQUEST_EXPORT: 19,
	SALARY_COMPUTATION: 20,
	PROJECT: 21,
	BUDGET: 23,
	ODOO_JSON_CONFIG: 24
};

export const FILTER_TYPE = {
	BUDGET_PER_CATEGORY: 0,
	BUDGET_SALARIES: 1,
	BUDGET_EXPENSES: 2,
	BUDGET_POS: 3,
	BUDGET_EXPENSE_SHEETS: 4,
	GREEN_TABLE: 5,
	JOB_REQUEST: 6
};

export const DOCUMENT_DELIVERY_STATUS = {
	NOT_PUBLISHED: 0,
	NOT_RECEIVED: 1,
	RECEIVED: 2,
	READ: 3,
	SIGNED: 4, // to disable
	VALIDATED: 5, // to disable
	SENT: 6,
	REFUSED: 7 // to disable
};

export const OPERATION_WITHOUT_TOKEN = [
	'GetAllFormsContent',
	'GetUserRequestInfo',
	'GetNewPasswordRequestInfo',
	'GET_USER_LAST_PROJECT',
	'GET_LICENSE_REQUEST',
	'CheckLicenseEmailValidationCode',
	'EMAIL_VALIDATION_CODE',
	'CheckLicensePhoneValidationCode',
	'PHONE_CODE_VALIDATION',
	'UpdAppLanguage',
	'GetUserByEmail',
	'SubmitJobRequest',
	'VerifyRecaptcha'
];

export const IMAGE_FILE_TYPE = '.png,.jpeg,.jpg,.heic';
export const PDF_FILE_TYPE = '.pdf';
export const OFFICE_FILE_TYPE = '.docx,.xlsx,.xlsm,.csv';
export const OTHER_FILE_TYPE = '.txt,.js,.json';
